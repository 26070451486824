import React from 'react';
import { observer, inject } from 'mobx-react';
import Login from './login/index';
// import SecretaryLogin from './login/SecretaryLogin';
@inject('tokenStore')
@observer
class AuthGuard extends React.Component {
  render() {
    const { children, tokenStore } = this.props;
    if (`${tokenStore.value}`.trim() !== '') {
      return children;
    }
    return <Login to={children} />;
  }
}

export default AuthGuard;
